<template>
  <img src="@/assets/images/key-01.svg" :height="height" :width="width" :alt="alt" >
</template>

<script>
export default {
props:{
    height:Number,
    width:Number,
    alt:String
}
}
</script>

<style>

</style>